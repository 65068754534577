import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../styles/_404.module.scss"

const NotFoundPage = () => (
  <Layout home={false} page={`404`}>
    <div className={styles.wrapper}>
      <h1 className={styles.title}>404<span>Not Found</span></h1>
      <p className={styles.message}>ページが見つかりませんでした</p>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404 Not Found" />

export default NotFoundPage
